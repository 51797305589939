const $ = require('jquery');
const header = require('../../../templates').header;
const translations = require('../../../translations');

const INFO_CLASSNAME = 'GLOBAL_INFO';
const ERROR_CLASSNAME = 'GLOBAL_ERROR';

/**
 * Shows a global info message
 *
 * @param {Node[]} listContainer contains list container element
 * @param {String} language Language from list response
 */
const showGlobalInfoMessage = (listContainer, language) => {
    const { title, description } = getTranslations(language);
    const headerElement = header({ title, description });
    removePrevInfoError(listContainer, INFO_CLASSNAME);
    removePrevInfoError(listContainer, ERROR_CLASSNAME);
    listContainer[0]?.insertAdjacentHTML('afterbegin', headerElement);
};

const getTranslations = (language) => {
    const translation = translations[language] ?? translations.en;
    const title = translation['networks.preset.title'];
    const description = translation['networks.preset.text'];
    return { title, description };
};

const removePrevInfoError = (listContainer, classname) => {
    const element = document.getElementsByClassName(classname)?.[0];
    element?.remove();
};

/**
 * Shows a global error message.
 *
 * @param {Node[]} listContainer contains list container element
 * @param {String} text The message to show
 * @param {String} title The title of message
 */
function showGlobalErrorMessage(listContainer, text, title) {
    if (text === '') {
        return;
    }
    let errorContent = '';
    removePrevInfoError(listContainer, INFO_CLASSNAME);
    if (listContainer.has('.GLOBAL_ERROR').length === 0) {
        listContainer.prepend($('<div class="GLOBAL_ERROR list"></div>'));
    }
    if (title) {
        errorContent += "<div class='global-error-title'>" + title + '</div>';
    }
    errorContent += "<div class='global-error-description'>" + text + '</div>';
    listContainer.find('.GLOBAL_ERROR').html(errorContent);
}
module.exports = { showGlobalInfoMessage, showGlobalErrorMessage };
