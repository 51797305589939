var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(((helper = (helper = lookupProperty(helpers,"dimensionClass") || (depth0 != null ? lookupProperty(depth0,"dimensionClass") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"dimensionClass","hash":{},"data":data,"loc":{"start":{"line":3,"column":88},"end":{"line":3,"column":106}}}) : helper)));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"lb-overlay\" class=\"op-payment-widget-lb-overlay\">\n    <div class=\"op-payment-widget-lb-overlay-content\">\n        <iframe id=\"lb-iframe\" class=\"op-payment-widget-lb-iframe "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"dimensionClass") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":66},"end":{"line":3,"column":113}}})) != null ? stack1 : "")
    + "\" src=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"redirectUrl") || (depth0 != null ? lookupProperty(depth0,"redirectUrl") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"redirectUrl","hash":{},"data":data,"loc":{"start":{"line":3,"column":120},"end":{"line":3,"column":135}}}) : helper)))
    + "\"></iframe>\n        <div class=\"op-payment-widget-lb-loaderContainer\" id=\"lb-overlay-loader\">\n            <img alt=\"network-logo\" src=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"logoUrl") || (depth0 != null ? lookupProperty(depth0,"logoUrl") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"logoUrl","hash":{},"data":data,"loc":{"start":{"line":5,"column":41},"end":{"line":5,"column":52}}}) : helper)))
    + "\" class=\"op-payment-widget-lb-logo\">\n            <div class=\"op-payment-widget-lb-loader\" role=\"progressbar\">\n                <svg class=\"op-payment-widget-lb-loader-svg\" viewBox=\"22 22 44 44\">\n                    <circle class=\"op-payment-widget-lb-loader-circle\" cx=\"44\" cy=\"44\" r=\"20.2\" fill=\"none\" stroke-width=\"3.6\"></circle>\n                </svg>\n            </div>\n        </div>\n    </div>\n</div>\n";
},"useData":true});