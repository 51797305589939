/**
 * returns true when the operation type is UPDATE, redirect doesn't exist and reason is PENDING
 * @param {Object} param contains isUpdate, redirect, reason
 * @param {Booelan} param.isUpdate - whether the operation type is UPDATE
 * @param {Object} param.redirect - redirect object from OPG response - redirect will exist in case of 3DS
 * @param {String} param.reason - interation.reason from OPG response
 * 
 * @returns {Boolean} true when the operation type is UPDATE, redirect doesn't exist and reason is PENDING
 */
const isUpdateWithPending = ({ isUpdate, redirect, reason }) => isUpdate && !redirect && reason === 'PENDING';

module.exports = { isUpdateWithPending };
